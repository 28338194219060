import React from "react";
import { makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  badge: {
    display: "inline-block",
    borderRadius: "90%",
    width: "12px",
    height: "12px",
    marginRight: "10px",
  },
  badgeRed: {
    backgroundColor: "red",
  },
  badgePurple: {
    backgroundColor: "purple",
  },
  badgeGreen: {
    backgroundColor: "green",
  },
  badgeOrange: {
    backgroundColor: "orange",
  },
  badgeBlue: {
    backgroundColor: "blue",
  },
});

const CustomBadge = ({ status }) => {
  const styles = useStyles();

  const getBadgeColor = () => {
    switch (status) {
      case "UPCOMING":
        return styles.badgeBlue;
      case "IN_PROCESS":
        return styles.badgePurple;
      case "IN_PROGRESS":
        return styles.badgeOrange;
      case "COMPLETED":
        return styles.badgeGreen;
      case "CANCELLED":
        return styles.badgeRed;
      case "FAILED":
        return styles.badgeRed;
      default:
        return null;
    }
  };

  return <div className={`${styles.badge} ${getBadgeColor()}`} />;
};

export default CustomBadge;
