import React from "react";
import { Button, makeStyles, tokens } from "@fluentui/react-components";
import { ChevronLeft12Regular, ChevronRight12Regular } from "@fluentui/react-icons";

const useOverrides = makeStyles({
  button: {
    minWidth: "30px",
  },
});

const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
  const overrides = useOverrides();

  const goToNextPage = () => {
    if (currentPage < nPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderPgNum = () => {
    const pageNumbers = [];
    for (let i = 1; i <= nPages; i++) {
      pageNumbers.push(
        <Button
          className={overrides.button} 
          key={i}
          shape="circular"
          onClick={() => setCurrentPage(i)}
          {...(currentPage === i ? { appearance: "primary" } : {appearance:'transparent'})}
        >
          {i}
        </Button>
      );
    }
    return pageNumbers;
  };

  return (
    <div>
      <Button  className={overrides.button} appearance="transparent" onClick={goToPrevPage}><ChevronLeft12Regular/></Button>
      {renderPgNum()}
      <Button  className={overrides.button} appearance="transparent" onClick={goToNextPage}><ChevronRight12Regular/></Button>
    </div>
  );
};

export default Pagination;
