import React, { useState, useEffect } from "react";
import { Persona, Stack, Toggle, DefaultPalette } from "@fluentui/react";
import { Text, Spinner } from "@fluentui/react-components";
import {
  Clock20Regular,
  Mail20Regular,
  PersonAccounts20Regular,
  Settings20Regular,
  Stack20Regular,
  Status20Regular,
} from "@fluentui/react-icons";
import "./css/profile.css";

const Profile = () => {
  const [profileData, setProfileData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [salesforceIntegration, setSalesforceIntegration] = useState(false);
  const [calendarOptions, setCalendarOptions] = useState([]);
  const [, setSelectedCalendar] = useState(null);
  const [shouldReload] = useState(false);

  const fetchData = async () => {
    try {
      const token = sessionStorage.getItem("accessToken");

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/user/settings?platform=msteams`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();

      setProfileData(data.data);
      setCalendarOptions(data.data.calendarIntegrations);
  
      if (data.data.botIntegrations) {
        setSalesforceIntegration(!!data.data.botIntegrations[0].isActive);
      }

      const activeCalendar = data.data.calendarIntegrations.find(
        (calendar) => calendar.isActive
      );

      if (activeCalendar) {
        setSelectedCalendar(activeCalendar.label);
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching profile data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (shouldReload) {
      window.location.reload();
    }
  }, [shouldReload]);

  const handleSalesforceToggle = async () => {
    if (!profileData || !profileData.botIntegrations) {
      console.error("Bot integrations data not available");
      return;
    }

    const salesforceIntegrationData = profileData.botIntegrations.find(
      (integration) => integration.label === "Salesforce"
    );

    if (!salesforceIntegrationData) {
      console.error("Salesforce integration data not found");
      return;
    }

    const { link } = salesforceIntegrationData;
    const userId = profileData.userInfo.id;

    setSalesforceIntegration((prevStatus) => {
      if (prevStatus) {
        const integrationName = "Salesforce";
        const token = sessionStorage.getItem("accessToken");

        fetch(
          `${process.env.REACT_APP_API_URL}/manage-integration?userId=${userId}&integrationName=${integrationName}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error("Failed to deactivate Salesforce integration");
            }
            sessionStorage.setItem("lastActiveTab", "profile");
            window.location.reload();
          })
          .catch((error) => {
            console.error("Error deactivating Salesforce integration:", error);
          });
      } else {
        if (link) {
          const constructedLink = `${link}&userId=${userId}`;
          window.open(constructedLink, "_blank");
        } else {
          console.error("Salesforce link not provided");
        }
      }
      return !prevStatus;
    });
  };

  const handleCalendarToggle = async (calendarLabel, isActive) => {
    const calendarIntegrationData = profileData?.calendarIntegrations?.find(
      (calendar) => calendar.label === calendarLabel
    );
    if (!calendarIntegrationData) {
      console.error("Calendar integration data not found");
      return;
    }

    const { link } = calendarIntegrationData;
    const userId = profileData?.userInfo?.id;

    if (!userId) {
      console.error("User ID not available");
      return;
    }

    if (!isActive) {
      setCalendarOptions((prevOptions) =>
        prevOptions.map((option) => ({
          ...option,
          isActive: option.label === calendarLabel,
        }))
      );

      if (link) {
        const constructedLink = `${link}&userId=${userId}`;
        window.open(constructedLink, "_blank");
      } else {
        console.warn(`${calendarLabel} link not provided`);
      }
    } else {
      const token = sessionStorage.getItem("accessToken");

      fetch(
        `${process.env.REACT_APP_API_URL}/api/mpb/logout?userId=${userId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `Failed to deactivate ${calendarLabel} integration`
            );
          }

          setCalendarOptions((prevOptions) =>
            prevOptions.map((option) => ({
              ...option,
              isActive: false,
            }))
          );

          window.location.reload();
        })
        .catch((error) => {
          console.error(
            `Error deactivating ${calendarLabel} integration: `,
            error
          );
        });
    }
  };
  if (isLoading) {
    return <Spinner label="Loading..." size="large" className="spinner" />;
  }

  if (!profileData) {
    return (
      <Stack className="no-user">
        <Text weight="bold">
          User details not found. Please login or try again later.{" "}
        </Text>
      </Stack>
    );
  }

  const formattedRole = profileData?.userInfo?.role
    ? profileData.userInfo?.role
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    : "";

  const calendarOptionsList = calendarOptions.map((option) => ({
    key: option.label,
    text: option.label,
    disabled: option.isDisabled,
    checked: option.isActive,
  }));

  const subscriptionDate = profileData?.licenseAndPlanDetails?.expiryDate
    ? new Date(profileData.licenseAndPlanDetails.expiryDate).toLocaleString()
    : "N/A";

  const isSubscriptionActive =
    profileData?.licenseAndPlanDetails?.isLicenseExpired;

  const containerStyles = {
    // maxWidth: "fit-content",
    maxWidth: "80%",
    margin: "50px auto",
    border: "1px solid #eaeaea",
    boxShadow: "0 4px 8px #00000",
    height: "100%",
  };
  const stackStyles = {
    root: {
      overflow: "visible",
      margin: 15,
    },
  };
  const wrapStackTokens = { childrenGap: 30 };
  const itemStyles = {
    alignItems: "left",
    color: DefaultPalette.black,
    display: "flex",
    height: 10,
    justifyContent: "left",
    minWidth: "calc(50% - 50px)",
    maxWidth: "calc(100% - 50px)",
    padding: 10,
    gap: "5px",
  };
  const headerStyles = {
    padding: 20,
  };
  const titleStyles = {
    padding: 15,
  };
  const userMailStyle = {
    overflowWrap: "word-wrap",
    flexWrap: "wrap",
    wordWrap: "break-word",

    minWidth: "calc(50% - 50px)",
  };

  return (
    <div style={containerStyles}>
      <Stack
        enableScopedSelectors
        horizontal
        verticalFill
        wrap
        horizontalAlign="center"
        style={titleStyles}
      >
        <Text style={{ fontSize: "20px", fontWeight: "bold" }}>
          Profile Details
        </Text>
      </Stack>
      <Stack
        enableScopedSelectors
        horizontal
        verticalFill
        wrap
        horizontalAlign="start"
        verticalAlign="start"
        style={headerStyles}
      >
        <Persona
          text={profileData?.userInfo?.name || "N/A"}
          secondaryText={profileData?.userInfo?.email || "N/A"}
        />
      </Stack>
      <Text weight="bold" className="headings">
        Personal Information
      </Text>
      <Stack
        enableScopedSelectors
        horizontal
        verticalFill
        wrap
        horizontalAlign="start"
        verticalAlign="start"
        styles={stackStyles}
        tokens={wrapStackTokens}
      >
        <div style={itemStyles}>
          <PersonAccounts20Regular />
          <Text variant="small"> Name: </Text>
          <Text>{profileData?.userInfo?.name || "N/A"}</Text>
        </div>
        <div style={itemStyles}>
          <div>
            <Mail20Regular />
          </div>
          <Text variant="small">Subscription Email:</Text>
          <Text style={userMailStyle}>
            {profileData?.userInfo?.email || "N/A"}
          </Text>
        </div>
        <div style={itemStyles}>
          <Settings20Regular />
          <Text variant="small"> Role: </Text>
          <Text>{formattedRole || "N/A"}</Text>
        </div>
        <div style={itemStyles}>
          <Status20Regular />
          <Text variant="small">Plan Name: </Text>
          <Text>{profileData?.licenseAndPlanDetails?.planName || "N/A"}</Text>
        </div>
        <div style={itemStyles}>
          <Stack20Regular />
          <Text variant="small">License Expired:</Text>
          <Text>{isSubscriptionActive ? "Yes" : "No"}</Text>
        </div>
        <div style={itemStyles}>
          <Clock20Regular />
          <Text variant="small">Date of Expiry:</Text>
          <Text> {subscriptionDate}</Text>
        </div>
      </Stack>
      <Stack>
        <Text weight="bold" className="headings">
          Integrations
        </Text>
      </Stack>
      <table>
        <tbody>
          {calendarOptionsList.map((option) => (
            <tr key={option.key}>
              <td style={{ padding: "15px" }}>
                <Text variant="small">{option.text}</Text>
              </td>
              <td style={{ paddingTop: "15px" }}>
                <Toggle
                  checked={option.checked}
                  onChange={() =>
                    handleCalendarToggle(option.text, option.checked)
                  }
                />
              </td>
            </tr>
          ))}
          <tr>
            <td style={{ padding: "15px" }}>
              <Text variant="small">Salesforce </Text>
            </td>
            <td style={{ paddingTop: "15px" }}>
              <Toggle
                checked={salesforceIntegration}
                onChange={handleSalesforceToggle}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Profile;
