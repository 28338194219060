import { Stack } from "@fluentui/react";
import React from "react";
import Copilotforce from "../assets/Copilotforce.svg";

const NotFound = () => {
    return (
        <Stack>
            <div className='container'>
                <div className="logo">
                    <img src={Copilotforce} alt="Your Logo" />
                </div>
                <h1>404 - Not Found</h1>
                <p>Sorry, the page you are looking for does not exist.</p>
            </div>
        </Stack>
    )
};

export default NotFound;
