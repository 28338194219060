
import React from "react";
import { Routes, Route } from "react-router-dom";
import MarkComponent from "../modules/MarkComponent";
import MeetingDetailsPage from "../modules/MeetingDetailsPage";
import NotFound from "../modules/NotFound";
import ViewMeetingDetails from "../modules/ViewMeetingDetails";

const TeamsRoutes = () => (
    <Routes>
        <Route path="/index.html" element={<MarkComponent />} />
        <Route path="/" element={<MarkComponent />} />
        <Route
            path="/meeting-details/:meetingId"
            element={<MeetingDetailsPage />}
        />
        <Route
            path="/meeting-details/transcripts/:meetingId"
            element={<ViewMeetingDetails />}
        />
        <Route path="/meeting-container-mark/:currTab/:parentTab" element={<MarkComponent/>} />
        <Route path="*" element={<NotFound />} />

    </Routes>
);

export default TeamsRoutes;
