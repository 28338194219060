import React from "react";
import {
  ChevronRight20Filled,
  DeviceMeetingRoom24Regular,
  VideoRegular,
} from "@fluentui/react-icons";
import {
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHeader,
  TableHeaderCell,
  TableCellLayout,
  Button,
  Link,
  Text,
} from "@fluentui/react-components";
import "./css/meetingtable.css";
import { Stack } from "@fluentui/react";
import CustomBadge from "./CustomBadge";
const MeetingTable = ({
  keyboardNavAttr,
  focusableGroupAttr,
  columns,
  upcomingMeetings,
  filteredMeetings,
  handleRowClick,
  handleCancelCall,
  handleEndCall,
  handleProcessCall,
  showToast,
  buttonClicked,
  completedMeetings,
  activeTab = "Conference Call History",
  handleCallbackFromMark,
}) => {
  return (
    <div>
      <Table
        {...keyboardNavAttr}
        role="grid"
        aria-label="Meeting Information Table"
        className="table-container"
      >
        <TableHeader>
          <TableRow>
            {columns.map((column) => (
              <TableHeaderCell key={column.columnKey} style={{ width: "18%" }}>
                {column.label}
              </TableHeaderCell>
            ))}
            <TableHeaderCell />
          </TableRow>
        </TableHeader>
        <TableBody>
          {((activeTab === "Upcoming" &&
            upcomingMeetings.length === 0 &&
            filteredMeetings.length === 0) ||
            (activeTab === "Conference Call History" &&
              completedMeetings.length === 0 &&
              filteredMeetings.length === 0)) && (
            <Stack colSpan={columns.length + 1} className="no-meetings-message">
              No meetings found.
            </Stack>
          )}
          {filteredMeetings.map((item) => (
            <TableRow
              key={item._id}
              className={buttonClicked ? "button-clicked-row" : ""}
              onClick={
                item.status === "COMPLETED" &&
                item.metadata &&
                item.metadata.length > 0
                  ? () => handleRowClick(item)
                  : undefined
              }
              style={{
                cursor:
                  item.status === "COMPLETED" &&
                  item.metadata &&
                  item.metadata.length > 0
                    ? "pointer"
                    : "not-allowed",
              }}
            >
              <TableCell tabIndex={0} role="gridcell">
                <TableCellLayout media={<VideoRegular />}>
                  {item.meetingName}
                </TableCellLayout>
              </TableCell>
              <TableCell tabIndex={0} role="gridcell">
                <TableCellLayout>
                  {new Date(item.meetingTime).toLocaleString("en-US", {
                    weekday: "short",
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    timeZone: item.timezone,
                  })}
                </TableCellLayout>
              </TableCell>
              <TableCell tabIndex={0} role="gridcell">
                <TableCellLayout>
                  <CustomBadge status={item.status}></CustomBadge>
                  {item.status
                    .split("_")
                    .map(
                      (word) =>
                        word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase()
                    )
                    .join(" ")}
                </TableCellLayout>
              </TableCell>
              <TableCell tabIndex={0} role="gridcell">
                <TableCellLayout>
                  <Link
                    href={item.meetingLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => {
                      if (item.status === "COMPLETED") {
                        e.stopPropagation();
                      }
                    }}
                  >
                    <DeviceMeetingRoom24Regular />
                  </Link>
                </TableCellLayout>
              </TableCell>
              <TableCell role="gridcell" tabIndex={0} {...focusableGroupAttr}>
                <TableCellLayout>
                  {item.status === "UPCOMING" && (
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCancelCall(item.id, item.workFlowId);
                        showToast("Meeting cancelled successfully!", "success");
                      }}
                    >
                      Cancel Call
                    </Button>
                  )}
                  {item.status === "IN_PROGRESS" && (
                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEndCall(
                            true,
                            item.id,
                            item.userId,
                            item.sessionId
                          );
                          showToast("Meeting ended successfully!", "success");
                        }}
                      >
                        End Call
                      </Button>
                      {/* <Button>Transcript</Button> */}
                    </Stack>
                  )}
                  {item.status === "FAILED" && (
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleProcessCall(item.userId, item.sessionId);
                      }}
                    >
                      Process Call
                    </Button>
                  )}
                </TableCellLayout>
                {item.status === "COMPLETED" &&
                item.metadata &&
                item.metadata.length === 0 ? (
                  <Stack style={{ paddingLeft: "18px" }}>
                    <Text>-</Text>
                  </Stack>
                ) : item.status === "COMPLETED" &&
                  item.metadata &&
                  item.metadata.length > 0 ? (
                  <Stack>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCallbackFromMark(item.id, item.userId);
                      }}
                    >
                      <Text>Request Callback from Mark</Text>
                    </Button>
                  </Stack>
                ) : null}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default MeetingTable;
