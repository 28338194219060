import React, { useState, useEffect } from "react";
import { Tab, TabList } from "@fluentui/react-components";
import { Stack } from "@fluentui/react";
import "./css/markcomponent.css";
import MeetingContainer from "./MeetingsContainer";
import Profile from "./Profile";
import { useParams } from "react-router-dom";

const MarkComponent = () => {
  const { currTab, parentTab } = useParams();
  const [activeTab, setActiveTab] = useState(parentTab || "Profile");

  useEffect(() => {
    if (parentTab && parentTab !== activeTab) {
      setActiveTab(parentTab);
    }
  }, [parentTab]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const renderTabs = () => {
    return ["Profile", "CopilotMeet"];
  };

  return (
    <Stack className="tabs">
      <TabList selectedValue={activeTab} style={{ margin: "15px 0" }}>
        {renderTabs().map((tab) => (
          <Tab key={tab} value={tab} onClick={() => handleTabChange(tab)}>
            {tab}
          </Tab>
        ))}
      </TabList>
      <Stack>
        {activeTab === "Profile" && <Profile />}
        {activeTab === "CopilotMeet" && (
          <MeetingContainer activeTab={currTab} />
        )}
      </Stack>
    </Stack>
  );
};

export default MarkComponent;
