import React from "react";
import { Routes, Route } from "react-router-dom";
import LoginPage from "../modules/LoginPage";
import NotFound from "../modules/NotFound";
const PublicRoutes = () => (
  <Routes>
    <Route path="/" element={<LoginPage />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default PublicRoutes;
