import React from 'react';
import { Stack, } from '@fluentui/react';
import './css/loginpage.css';
import Copilotforce from "../assets/Copilotforce.svg";
import { Text } from '@fluentui/react-components';
const LoginPage = () => {
    return (
        <Stack>
            <div className='container'>
                <div className="logo">
                    <img src={Copilotforce} alt="Copilotforce Logo" />
                </div>
                <Text weight='bold' className="text">Please Login to Copilotforce on Teams App</Text>
            </div>
        </Stack>
    );
};

export default LoginPage;
