import React from "react";
import "./css/copilotmeet.css";

const CopilotMeet = () => {

  return (
    <div className="free-plan-container">
      <p className="message">
      Upgrade your plan to access CopilotMeet feature and elevate your experience! You can contact us at <a href="mailto:support@techtonik.ai" >support@techtonik.ai</a>.
       </p>
      <ul className="features-list">
        <li>Have Mark Join Microsoft Teams and Google Meet calls</li>
        <li>Get Live and Recorded Transcripts</li>
        <li>Summarize Conversations and Create Action Items</li>
        <li>Convert Actions Items to Trigger Salesforce Operations</li>
      </ul>
    </div>
  );
};

export default CopilotMeet;