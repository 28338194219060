import * as React from "react";
import {
  useArrowNavigationGroup,
  useFocusableGroup,
  Field,
  Spinner,
  Toaster,
  useId,
  useToastController,
  Toast,
  ToastTitle,
  Tab,
  TabList,
} from "@fluentui/react-components";
import { Stack } from "@fluentui/react";
import { useState, useEffect } from "react";
import { SearchBox } from "@fluentui/react-search-preview";
import "../App.css";
import { useNavigate } from "react-router";
import MeetingCard from "../components/MeetingCard";
import { AddMeetDialog } from "../components/AddMeetDialog";
import Pagination from "../components/Pagination";

const columns = [
  { columnKey: "name", label: "Meeting Name" },
  { columnKey: "date", label: "Date and Time" },
  { columnKey: "status", label: "Status" },
  { columnKey: "link", label: "Meeting Link" },
  { columnKey: "actions", label: "Action" },
];

const MeetingInformationMobile = ({ currentTab }) => {
  const keyboardNavAttr = useArrowNavigationGroup({ axis: "grid" });
  const focusableGroupAttr = useFocusableGroup({
    tabBehavior: "limited-trap-focus",
  });
  const tabs = ["Conference Call History", "Upcoming Meetings"];
  const [activeTab, setActiveTab] = useState(
    currentTab || "Conference Call History"
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [, setIsProcessingSuccess] = useState(false);
  const [, setUserId] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);
  const [meetAdded, setMeetAdded] = useState(false);
  const [upcomingMeetings, setUpcomingMeetings] = useState([]);
  const [completedMeetings, setCompletedMeetings] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalPagesUp, setTotalPagesUp] = useState(0);
  const [completedMeetingPage, setCompletedMeetingPage] = useState(1);
  const [upcomingMeetingPage, setUpcomingMeetingPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const navigate = useNavigate();

  const onChange = (e, newValue) => {
    setSearchTerm(newValue.value);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 2000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchTerm]);

  useEffect(() => {
    if (currentTab && currentTab !== activeTab) {
      setActiveTab(currentTab);
    }
  }, [currentTab]);

  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const showToast = (message, intent) => {
    dispatchToast(
      <Toast>
        <ToastTitle>{message}</ToastTitle>
      </Toast>,
      { intent }
    );

    setTimeout(() => {
      dispatchToast(null);
    }, 5000);
  };

  useEffect(() => {
    const FetchUpcomingCallData = async () => {
      try {
        const token = sessionStorage.getItem("accessToken");

        setIsLoading(true);

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/multi-plaform/call-history?page=${upcomingMeetingPage}&searchTerm=${searchTerm}&row=10&status=UPCOMING,IN_PROGRESS`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data", data);
        }

        const data = await response.json();

        if (
          !data.status ||
          data.status !== "SUCCESS" ||
          !data.data ||
          !data.data.meetings ||
          !data.data.meetings.callHistory ||
          data.data.meetings.totalPages === undefined ||
          data.data.meetings.totalDocuments === undefined
        ) {
          console.error("Invalid response format:", data);
          return;
        }
        setTotalPagesUp(data.data.meetings.totalPages);
        setUpcomingMeetings(data.data.meetings.callHistory);
        setTotalDocuments(data.data.meetings.totalDocuments);
        setMeetAdded(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    FetchUpcomingCallData();
  }, [
    activeTab,
    debouncedSearchTerm,
    meetAdded,
    buttonClicked,
    upcomingMeetingPage,
  ]);

  useEffect(() => {
    const FetchCompletedCallData = async () => {
      try {
        const token = sessionStorage.getItem("accessToken");

        setIsLoading(true);

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/multi-plaform/call-history?page=${completedMeetingPage}&searchTerm=${searchTerm}&row=10&status=COMPLETED,IN_PROCESS,CANCELLED,FAILED`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data", data);
        }

        const data = await response.json();

        if (
          !data.status ||
          data.status !== "SUCCESS" ||
          !data.data ||
          !data.data.meetings ||
          !data.data.meetings.callHistory ||
          data.data.meetings.totalPages === undefined ||
          data.data.meetings.totalDocuments === undefined
        ) {
          console.error("Invalid response format:", data);
          return;
        }
        setTotalPages(data.data.meetings.totalPages);
        // setCallHistory1(data.data.meetings.callHistory);
        setCompletedMeetings(data.data.meetings.callHistory);
        setMeetAdded(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    FetchCompletedCallData();
  }, [
    activeTab,
    debouncedSearchTerm,
    meetAdded,
    buttonClicked,
    completedMeetingPage,
  ]);

  if (isLoading) {
    return <Spinner label="Loading..." size="large" className="loading" />;
  }

  const handleTabClick = (newTab) => {
    setActiveTab(newTab);
  };

  const handleRowClick = (selectedItem) => {
    if (!buttonClicked) {
      const meetingId = selectedItem.id;
      const callHistory = [...upcomingMeetings, ...completedMeetings];
      const selectedMeeting = callHistory.find(
        (item) => item._id === meetingId
      );
      const serializableMeeting = {
        name: selectedMeeting.meetingName,
        link: selectedMeeting.meetingLink,
        date: selectedMeeting.meetingTime,
        status: selectedMeeting.status,
      };

      navigate(`/meeting-details/${meetingId}`, {
        state: {
          meeting: serializableMeeting,
          currTab: { activeTab },
          parentTab: "CopilotMeet",
        },
      });
    }
    setButtonClicked(false);
  };

  const filteredMeetings =
    activeTab === "Upcoming Meetings"
      ? upcomingMeetings
      : activeTab === "Conference Call History"
        ? completedMeetings
        : [];

  const noResultsFound = filteredMeetings.length === 0;

  const handleCancelCall = async (meetingId, workFlowId) => {
    try {
      const token = sessionStorage.getItem("accessToken");

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/mpb/cancel`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            meetingId: meetingId,
            workFlowId: workFlowId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to cancel the meeting");
      }

      showToast("Meeting canceled successfully!", "success");
      const totalDoc = totalDocuments;
      if (totalDoc % 10 === 1) {
        setUpcomingMeetingPage(upcomingMeetingPage - 1);
      } else {
        setTimeout(() => {
          setButtonClicked(true);
        }, 3000);
      }
    } catch (error) {
      console.error("Cannot cancel outdated meetings", "error");
    } finally {
      setButtonClicked(false);
    }
  };

  const handleEndCall = async (meetingId, userId, sessionId) => {
    try {
      const token = sessionStorage.getItem("accessToken");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/multi-plaform/end-call`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: userId,
            sessionId: sessionId,
            meetingId: meetingId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to end the meeting");
      }
      showToast("Meeting ended successfully!", "success");
      const totalDoc = totalDocuments;
      if (totalDoc % 10 === 1) {
        setUpcomingMeetingPage(upcomingMeetingPage - 1);
      } else {
        setTimeout(() => {
          setButtonClicked(true);
        }, 3000);
      }

      sessionStorage.setItem("lastActiveTab", "copilotmeet");
    } catch (error) {
      console.error("Error ending meeting.Please try again later.", "error");
    } finally {
      setButtonClicked(false);
    }
  };

  const handleProcessCall = async (userId, sessionId) => {
    try {
      const token = sessionStorage.getItem("accessToken");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/multi-plaform/process-call`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: userId,
            sessionId: sessionId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to process call. Status: ${response.status}`);
      }

      const responseData = (await response.json()) || {};

      if (responseData.status === "success") {
        showToast("Notes Processed successfully!", "success");
        setIsProcessingSuccess(true);
      } else if (
        responseData.status === 401 &&
        responseData.data?.message === "SF_AUTH_REQUIRED"
      ) {
        showToast(
          "Please authorize to Salesforce to process the call.",
          "error"
        );
      } else {
        showToast(
          "Error: Please authorize to Salesforce to process your notes.",
          "error"
        );
        console.error(
          "Failed to process the call:",
          responseData.message || "Unknown error"
        );
      }
    } catch (error) {
      showToast(
        "Error: Please authorize to Salesforce to process your notes.",
        "error"
      );
      console.error("Error processing call:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Stack horizontal className="header">
        <TabList selectedValue={activeTab}>
          {tabs.map((tab) => (
            <Tab key={tab} value={tab} onClick={() => handleTabClick(tab)}>
              {tab}
            </Tab>
          ))}
        </TabList>
        <Stack style={{ margin: "0 22vw" }} />

        <Field className="fieldWrapper">
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <AddMeetDialog setMeetAdded={setMeetAdded} />
            <SearchBox
              placeholder="Search"
              value={searchTerm}
              onChange={onChange}
            />
          </Stack>
        </Field>
      </Stack>
      {noResultsFound ? (
        <Stack className="no-results">No meetings found.</Stack>
      ) : (
        <Stack
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            paddingBottom: 2,
          }}
        >
          {/* this is where meetings must come */}
          <MeetingCard
            keyboardNavAttr={keyboardNavAttr}
            focusableGroupAttr={focusableGroupAttr}
            columns={columns}
            activeTab={activeTab}
            upcomingMeetings={upcomingMeetings}
            completedMeetings={completedMeetings}
            filteredMeetings={filteredMeetings}
            handleRowClick={handleRowClick}
            handleCancelCall={handleCancelCall}
            handleEndCall={handleEndCall}
            handleProcessCall={handleProcessCall}
            buttonClicked={buttonClicked}
            showToast={showToast}
          />
          {activeTab === "Conference Call History" ? (
            <Stack
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <Pagination
                nPages={totalPages}
                currentPage={completedMeetingPage}
                setCurrentPage={setCompletedMeetingPage}
              />
            </Stack>
          ) : (
            <Stack
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <Pagination
                nPages={totalPagesUp}
                currentPage={upcomingMeetingPage}
                setCurrentPage={setUpcomingMeetingPage}
              />
            </Stack>
          )}
        </Stack>
      )}
      <Toaster toasterId={toasterId} />
    </>
  );
};

export default MeetingInformationMobile;
