/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ArrowLeft24Regular } from "@fluentui/react-icons";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Spinner,
  Text,
  Button,
  useId,
  useToastController,
  Toast,
  ToastTitle,
} from "@fluentui/react-components";
import { Stack } from "@fluentui/react";
import "./css/meetingdetails.css";
import { useLocation } from "react-router-dom";

const MeetingDetailsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { meetingId } = useParams();
  const [callDetails, setCallDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [sfAuthenticated, setSfAuthenticated] = useState(true);

  const toasterId = useId("toaster-meeting-details");
  const { dispatchToast } = useToastController(toasterId);
  const showToast = (message, intent) => {
    dispatchToast(
      <Toast>
        <ToastTitle>{message}</ToastTitle>
      </Toast>,
      { intent }
    );

    setTimeout(() => {
      dispatchToast(null);
    }, 3000);
  };

  const handleViewClick = (viewPath) => {
    window.open(viewPath, "_blank");
  };

  const handleCallbackFromMark = async () => {
    try {
      const token = sessionStorage.getItem("accessToken");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/multi-plaform/post-processing/call-me`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: location.state.meeting?.userId,
            meetingId: meetingId,
          }),
        }
      );

      if (!response.ok) {
        showToast(
          "Failed to request callback; please try again later.",
          "error"
        );
      }

      showToast("Callback requested from Mark", "success");
    } catch (error) {
      showToast("Failed to request callback; please try again later.", "error");
    }
  };

  useEffect(() => {
    const fetchCallDetails = async () => {
      try {
        if (meetingId) {
          const token = sessionStorage.getItem("accessToken");

          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/multi-plaform/call-details?meetingId=${meetingId}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );

          const data = await response.json();

          if (data.message === "SF_AUTH_REQUIRED") {
            setSfAuthenticated(false);
          }
          setCallDetails(data.data.actionItems);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchCallDetails();
  }, [meetingId]);

  const handleBackClick = () => {
    navigate(
      `/meeting-container-mark/${location.state.currTab.activeTab}/${location.state.parentTab}`
    );
  };

  return (
    <>
      {isLoading ? (
        <Spinner label="Loading..." size="large" className="loading" />
      ) : (
        <Stack style={{ padding: "2vw" }} tokens={{ childrenGap: 10 }}>
          <Stack>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <ArrowLeft24Regular
                  onClick={handleBackClick}
                  style={{ cursor: "pointer" }}
                />
                <h3 style={{ textAlign: "left", paddingLeft: "10px" }}>
                  Meeting Information
                </h3>
              </div>
              <Button
                onClick={() => {
                  handleCallbackFromMark();
                }}
                style={{ marginLeft: "auto", cursor: "pointer" }}
              >
                <Text>Request Callback from Mark</Text>
              </Button>
            </div>
          </Stack>
          {callDetails && callDetails.length > 0 ? (
            <Accordion multiple collapsible defaultCollapsed={false}>
              {callDetails.map((item) => (
                <AccordionItem key={item.id} value={item.id}>
                  <div className="card-container">
                    <AccordionHeader className="card-header">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          flexWrap: "wrap",
                        }}
                      >
                        <b>{`Notes`}</b>

                        <div
                          style={{
                            color: "#0078D4",
                            fontSize: "14px",
                            padding: "8px 30px",
                          }}
                        >
                          <Link
                            to={`/meeting-details/transcripts/${meetingId}`}
                            state={{ meetingDetails: item }}
                          >
                            View Meeting Transcript
                          </Link>
                        </div>
                      </div>
                    </AccordionHeader>
                    <AccordionPanel className="card-panel">
                      <div style={{ paddingBottom: "1vw" }}>
                        <Text variant="medium">
                          {/* <p>{`${item.callSummary.split("\n").slice(0, 3).join("\n")}`}</p> */}
                          <p>{`Tasks: ${item.taskCount}, Contacts: ${item.contactCount}, Leads: ${item.leadCount} , Accounts: ${item.accountCount}, Opportunities:  ${item.opportunityCount} ,  Upcoming Meetings: ${item.upcomingMeeting?.length} `}</p>
                        </Text>
                      </div>

                      {item.task &&
                        item.task?.length > 0 &&
                        item.task.map((meeting, index) => (
                          <Stack key={index} className="card-container">
                            <Accordion multiple collapsible>
                              <AccordionItem key={item.id} value={item.id}>
                                <AccordionHeader className="card-header">
                                  <Stack>
                                    <Stack>
                                      <Text weight="bold">
                                        {" "}
                                        Salesforce Task{" "}
                                      </Text>
                                      {meeting.Subject}
                                    </Stack>
                                    <Stack horizontal>
                                      <a
                                        href={meeting.url.replace(
                                          /^.*\/\/[^\/]+/,
                                          ""
                                        )}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleViewClick(meeting.url);
                                        }}
                                      >
                                        Create Salesforce Task
                                      </a>
                                    </Stack>
                                  </Stack>
                                </AccordionHeader>
                                <AccordionPanel className="card-panel">
                                  <p>Subject: {meeting.Subject || "N/A"}</p>
                                  <p>
                                    Description: {meeting.Description || "N/A"}
                                  </p>
                                  <p>
                                    Assigned To: {meeting.AssignedTo || "N/A"}
                                  </p>
                                  <p>
                                    Due Date: {meeting.ActivityDate || "N/A"}
                                  </p>
                                </AccordionPanel>
                              </AccordionItem>
                            </Accordion>
                          </Stack>
                        ))}
                      {item.lead &&
                        item.lead.length > 0 &&
                        item.lead.map((meeting, index) => (
                          <Stack key={index} className="card-container">
                            <Accordion multiple collapsible>
                              <AccordionItem key={item.id} value={item.id}>
                                <AccordionHeader className="card-header">
                                  <Stack>
                                    <Stack>
                                      <Text weight="bold">
                                        {" "}
                                        Salesforce Lead{" "}
                                      </Text>
                                      {meeting.Company}
                                    </Stack>
                                    <Stack horizontal>
                                      <a
                                        href={meeting.url.replace(
                                          /^.*\/\/[^\/]+/,
                                          ""
                                        )}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleViewClick(meeting.url);
                                        }}
                                      >
                                        Create Salesforce Lead
                                      </a>
                                    </Stack>
                                  </Stack>
                                </AccordionHeader>
                                <AccordionPanel className="card-panel">
                                  <p>
                                    First Name: {meeting.FirstName || "N/A"}{" "}
                                  </p>
                                  <p>Last Name: {meeting.LastName || "N/A"}</p>
                                  <p>Company: {meeting.Company || "N/A"}</p>
                                  <p>Email: {meeting.Email || "N/A"}</p>
                                  <p>Title: {meeting.Title || "N/A"}</p>
                                  <p>Phone: {meeting.Phone || "N/A"}</p>
                                </AccordionPanel>
                              </AccordionItem>
                            </Accordion>
                          </Stack>
                        ))}
                      {item.account &&
                        item.account.length > 0 &&
                        item.account.map((meeting, index) => (
                          <Stack key={index} className="card-container">
                            <Accordion multiple collapsible>
                              <AccordionItem key={item.id} value={item.id}>
                                <AccordionHeader className="card-header">
                                  <Stack>
                                    <Stack>
                                      <Text weight="bold">
                                        {" "}
                                        Salesforce Account{" "}
                                      </Text>
                                      {meeting.Name}
                                    </Stack>
                                    <Stack horizontal>
                                      <a
                                        href={meeting.url.replace(
                                          /^.*\/\/[^\/]+/,
                                          ""
                                        )}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleViewClick(meeting.url);
                                        }}
                                      >
                                        Create Salesforce Account
                                      </a>
                                    </Stack>
                                  </Stack>
                                </AccordionHeader>
                                <AccordionPanel className="card-panel">
                                  <p>Name: {meeting.Name || "N/A"}</p>
                                  <p>Website: {meeting.Website || "N/A"}</p>
                                  <p>Phone: {meeting.Phone || "N/A"}</p>
                                </AccordionPanel>
                              </AccordionItem>
                            </Accordion>
                          </Stack>
                        ))}
                      {item.contact &&
                        item.contact.length > 0 &&
                        item.contact.map((meeting, index) => (
                          <Stack key={index} className="card-container">
                            <Accordion multiple collapsible>
                              <AccordionItem key={item.id} value={item.id}>
                                <AccordionHeader className="card-header">
                                  <Stack>
                                    <Stack>
                                      <Text weight="bold">
                                        {" "}
                                        Salesforce Contact{" "}
                                      </Text>
                                      {`${meeting.FirstName} ${meeting.LastName}`}
                                    </Stack>
                                    <Stack horizontal>
                                      <a
                                        href={meeting.url.replace(
                                          /^.*\/\/[^\/]+/,
                                          ""
                                        )}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleViewClick(meeting.url);
                                        }}
                                      >
                                        Create Salesforce Contact
                                      </a>
                                    </Stack>
                                  </Stack>
                                </AccordionHeader>
                                <AccordionPanel className="card-panel">
                                  <p>
                                    FirstName: {meeting.FirstName || "N/A"}{" "}
                                  </p>
                                  <p>Last Name: {meeting.LastName || "N/A"}</p>
                                  <p>
                                    Department: {meeting.Department || "N/A"}
                                  </p>
                                  <p>Email: {meeting.Email || "N/A"}</p>
                                  <p>Title: {meeting.Title || "N/A"}</p>
                                  <p>Phone: {meeting.Phone || "N/A"}</p>
                                </AccordionPanel>
                              </AccordionItem>
                            </Accordion>
                          </Stack>
                        ))}
                      {item.opportunity &&
                        item.opportunity.length > 0 &&
                        item.opportunity.map((meeting, index) => (
                          <Stack key={index} className="card-container">
                            <Accordion multiple collapsible>
                              <AccordionItem key={item.id} value={item.id}>
                                <AccordionHeader className="card-header">
                                  <Stack>
                                    <Stack>
                                      <Text weight="bold">
                                        {" "}
                                        Salesforce Opportunity{" "}
                                      </Text>
                                      {meeting.Name}
                                    </Stack>
                                    <Stack horizontal>
                                      <a
                                        href={meeting.url.replace(
                                          /^.*\/\/[^\/]+/,
                                          ""
                                        )}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleViewClick(meeting.url);
                                        }}
                                      >
                                        Create Salesforce Opportunity
                                      </a>
                                    </Stack>
                                  </Stack>
                                </AccordionHeader>
                                <AccordionPanel className="card-panel">
                                  <p>Name: {meeting.Name || "N/A"}</p>
                                  <p>
                                    Description: {meeting.Description || "N/A"}{" "}
                                  </p>
                                  <p>Amount: {meeting.Amount || "N/A"}</p>
                                  <p>Due Date: {meeting.CloseDate || "N/A"}</p>
                                </AccordionPanel>
                              </AccordionItem>
                            </Accordion>
                          </Stack>
                        ))}
                      {item.upcomingMeeting &&
                        item.upcomingMeeting.length > 0 &&
                        item.upcomingMeeting.map((meeting, index) => (
                          <Stack key={index} className="card-container">
                            <Accordion multiple collapsible>
                              <AccordionItem key={item.id} value={item.id}>
                                <AccordionHeader className="card-header">
                                  <Stack>
                                    <Stack>
                                      <Text weight="bold">
                                        {" "}
                                        Upcoming Meetings{" "}
                                      </Text>
                                      {meeting.Details}
                                    </Stack>
                                    <Stack horizontal>
                                      <a
                                        href={meeting.url.replace(
                                          /^.*\/\/[^\/]+/,
                                          ""
                                        )}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleViewClick(meeting.url);
                                        }}
                                      >
                                        Create New Meeting
                                      </a>
                                    </Stack>
                                  </Stack>
                                </AccordionHeader>
                                <AccordionPanel className="card-panel">
                                  <p>Details: {meeting.Details || "N/A"}</p>
                                  <p>Date: {meeting.Date || "N/A"}</p>
                                  <p>
                                    Time: {meeting.StartTime || "N/A"} -{" "}
                                    {meeting.EndTime || "N/A"}
                                  </p>
                                  <p>Location: {meeting.Location || "N/A"}</p>
                                </AccordionPanel>
                              </AccordionItem>
                            </Accordion>
                          </Stack>
                        ))}
                    </AccordionPanel>
                  </div>
                </AccordionItem>
              ))}
            </Accordion>
          ) : (
            <Stack className="no-notes">
              {sfAuthenticated ? (
                <Text>
                  Notes not found for this meeting. Please try again later.
                </Text>
              ) : (
                <Text>Authenticate with Salesforce to access this feature</Text>
              )}
            </Stack>
          )}
        </Stack>
      )}
    </>
  );
};

export default MeetingDetailsPage;
