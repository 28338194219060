import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useTeams } from "msteams-react-base-component";
import * as microsoftTeams from "@microsoft/teams-js";
import "./App.css";
import TeamsRoutes from "./routes/teamsRoutes";
import PublicRoutes from "./routes/publicRoutes";
import { Spinner } from "@fluentui/react-components";

function App() {
  const [{ inTeams }] = useTeams();
  const [authToken, setAuthToken] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initializeTeams = async () => {
      try {
        await microsoftTeams.app.initialize();
        const token = await microsoftTeams.authentication.getAuthToken();
        if (token) {
          await sendTokenToBackend(token);
          setAuthToken(token);
        }
      } catch (error) {
        console.error("Teams SDK authentication error:", error);
      } finally {
        setLoading(false);
      }
    };

    const sendTokenToBackend = async (token) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_AUTH_URL}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(
            `Failed to send token to backend. Status: ${response.status}`
          );
        }
        const responseData = await response.json();
        handleTokenResponse(responseData);
      } catch (error) {
        console.error("Error sending token to backend:", error);
      }
    };

    const handleTokenResponse = (responseData) => {
      if (
        responseData.status === "success" &&
        responseData.data &&
        responseData.data.tokenDetails &&
        responseData.data.tokenDetails.token
      ) {
        sessionStorage.setItem(
          "accessToken",
          responseData.data.tokenDetails.token
        );
        sessionStorage.setItem(
          "refreshToken",
          responseData.data.tokenDetails.refreshToken
        );
        sessionStorage.setItem(
          "accessTokenExpiration",
          responseData.data.tokenDetails.accessTokenExpiration
        );
      } else {
        console.error("Failed to update tokens. Response data:", responseData);
      }
    };

    const checkTokenExpiration = () => {
      const accessTokenExpiration = new Date(
        sessionStorage.getItem("accessTokenExpiration")
      );
      const currentTime = new Date();

      if (currentTime >= accessTokenExpiration) {
        const refreshToken = sessionStorage.getItem("refreshToken");

        if (refreshToken) {
          sendRefreshTokenToBackend(refreshToken);
        }
      }
    };

    const sendRefreshTokenToBackend = async (refreshToken) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/access-token`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${refreshToken}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(
            `Failed to refresh tokens. Status: ${response.status}`
          );
        }
        const responseData = await response.json();
        handleTokenResponse(responseData);
      } catch (error) {
        console.error("Error refreshing tokens:", error);
      }
    };

    if (inTeams === true) {
      initializeTeams();
    }
    checkTokenExpiration();
  }, [inTeams, authToken]);

  useEffect(() => {}, [authToken]);

  return (
    <div className="App">
      <Router>
        {inTeams ? (
          <>
            {loading ? (
              <Spinner label="Loading..." size="large" className="loading" />
            ) : (
              <TeamsRoutes />
            )}
          </>
        ) : (
          <PublicRoutes />
        )}
      </Router>
    </div>
  );
}

export default App;