import React from "react";
import { Stack, Text } from "@fluentui/react";
import { ArrowLeft24Regular } from "@fluentui/react-icons";
import { useNavigate, useLocation } from "react-router";
import "./css/viewmeeting.css";
const ViewMeetingDetails = () => {
  const location = useLocation();
  const meetingDetails = location.state && location.state.meetingDetails;
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const renderConversationLines = () => {
    if (!meetingDetails || !meetingDetails.conversation) {
      return null;
    }

    const lines = meetingDetails.conversation.split('\n');

    return lines.map((line, index) => (
      <p key={index} style={{ display: 'flex', textAlign: 'left' }}>{line}</p>
    ));
  };

  return (
    <Stack>
      <Stack>
        <div className="headerContainer">
          <ArrowLeft24Regular onClick={handleBackClick} style={{ cursor: 'pointer' }} />
          <h3 className="titleContainer">Transcripts</h3>
        </div>
      </Stack>
      {meetingDetails && (
        <Stack className="detailsContainer">
          <div className="transcriptsPanel">
            <Text variant="medium">Call Transcripts</Text>
            {renderConversationLines()}
          </div>
          <div className="summaryPanel">
            <Text variant="medium">Meeting Summary</Text>
            <div>
              <p className="summaryParagraph">{meetingDetails.callSummary}</p>
            </div>
          </div>
        </Stack>
      )}
    </Stack>
  );
};

export default ViewMeetingDetails;
